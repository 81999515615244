import React from "react";
import "./WhiteIconCard.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faInfo} from "@fortawesome/free-solid-svg-icons";

export interface WhiteIconCardProps
{
    icon: IconProp;
    title: string;
    content: string;
}

const WhiteIconCard = ({icon, title, content}: WhiteIconCardProps) =>
{
    return (
        <div className="ic-card-w">
            <div className="icon-circle-w">
                <FontAwesomeIcon icon={icon || faInfo} className="i-icon-w"/>
            </div>
            <div className="i-card-body-w">
                <p className="i-card-title-w">{title}</p>
                <p className="i-card-text-w">{content}</p>
            </div>
        </div>
    );
}

export default WhiteIconCard;
