import React, {useState} from "react";
import "./ClickableCardCarousel.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import {responsive} from "../../../carousel-responsive/responsive";
import {useMediaQuery} from "react-responsive";
import ImageCard, {ImageCardProps} from "../image-card/ImageCard";

interface ClickableCardSetProps
{
    cards: ImageCardProps[];
}

const ClickableCardCarousel: React.FC<ClickableCardSetProps> = ({cards}) =>
{
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedCard, setSelectedCard] = useState<ImageCardProps | null>(null);
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    const handleCardClick = (card: ImageCardProps) =>
    {
        setSelectedCard(card);
        setShowOverlay(true);
    };

    const handleClose = () => setShowOverlay(false);

    return (
        <>
            <Carousel arrows={isDesktopOrLaptop} showDots={true} centerMode={true} responsive={responsive}
                      autoPlay={true} autoPlaySpeed={3000} infinite={true}>
                {cards.map((card, idx) => (
                    <a type="button" className="icon-carousel-cards"
                       onClick={() => handleCardClick(card)} key={idx}>
                        <ImageCard img={card.img} title={card.title.split(" ")[0]}/>
                    </a>
                ))}
            </Carousel>

            {showOverlay && (
                <div className="overlay">
                    <div className="modal show d-block" tabIndex={-1} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Médico</h5>
                                </div>
                                <div className="card">
                                    <div className="card-body c-blue">
                                        <h5 className="card-title">Nome: {selectedCard?.title}</h5>
                                        <p className="card-text">CRI: {selectedCard?.content}</p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Fechar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ClickableCardCarousel;