import {gql} from "@apollo/client";

export const homeQuery = gql`
  query {
    homeCollection(limit: 1) {
      items {
        headerTitle
        mainImg {
          url
        }
        firstCardSetCollection {
          items {
            content
            title
          }
        }
        secondCardSetCollection {
          items {
            content
            title
          }
        }
        sectionCarouselCollection {
          items {
             title
             content
             img {
               url
             }
          }
        }
        staffSectionCollection {
          items {
            content
            title
            img {
              url
            }
          }
        }
        subtitle
      }
    }
  }
`;

export const contactUsQuery = gql`
  query {
    contactUsPageCollection(limit: 1) {
      items {
        subjectsCollection {
          items {
            name
          }
        }
        frequentQuestionsCollection {
          items {
            question
            answer
          }
        }
        mainTitle
        emailsCollection {
          items {
            email
          }
        }
        subtitle
        whatsAppNumbersCollection {
          items {
            number
          }
        }
      }
    }
  }
`;

export const aboutUsQuery = gql`
  query {
    aboutUsPageCollection(limit: 1) {
      items {
        aboutUsText
        cardSetCollection {
          items {
            content
            title
          }
        }
        iconCardSetCollection {
          items {
            content
            title
          }
        }
        section {
          title
          content
          img {
            url
          }
        }
        subtitle
        mainTitle
        workWithUsSection {
          title
          content
          img {
            url
          }
        }
      }
    }
  }
`;

export const workWithUsQuery = gql`
  query {
    workWithUsPageCollection(limit: 1) {
      items {
        mainTitle
        subtitle
      }
    }
  }
`;

export const doctorPortalQuery = gql`
  query {
    doctorPortalPageCollection(limit: 1) {
      items {
        iconCardSetCollection {
          items {
            content
            title
          }
        }
        doctorCardSetCollection {
          items {
            content
            title
            img {
              url
            }
          }
        }
        subjectsCollection {
          items {
            name
          }
        }
        mainImg {
          url
        }
        mainTitle
        sectionContent
        sectionFirstTitle
        sectionSecondTitle
        subtitle
      }
    }
  }
`;

export const healthInsuranceQuery = gql`
  query {
    healthInsurancePageCollection(limit: 1) {
      items {
        cardSetCollection {
          items {
            content
            img {
              url
            }
            title
          }
        }
        firstSection {
          title
          content
          img {
            url
          }
        }
        mainTitle
        secondSectionImg {
          url
        }
        secondSectionText
        subtitle
      }
    }
  }
`;

export const staffQuery = gql`
  query {
    staffPageCollection(limit: 1) {
      items {
        firstSection {
          title
          content
          img {
            url
          }
        }
        secondSection {
          title
          content
          img {
            url
          }
        }
        subtitle
        mainTitle
      }
    }
  }
`;

export const unitsQuery = gql`
  query {
    unitsPageCollection(limit: 1) {
      items {
        firstSection {
          title
          content
          img {
            url
          }
        }
        secondSection {
          title
          content
          img {
            url
          }
        }
        subtitle
        mainTitle
      }
    }
  }
`;

export const examsQuery = gql`
  query {
      examsPageCollection(limit: 1) {
        items {
          mainTitle
          subtitle
          firstSectionTitle
          firstSectionContent
          imgCardSetCollection {
            items {
              content
              img {
                url
              }
              title
            }
          }
          examsCollection {
            items {
              name  
            }
          }
        }
      }
    }
  `;

export const footerQuery = gql`
  query {
    footerCollection(limit: 1) {
      items {
        location {
          lat
          lon
        }
        addressesCollection {
          items {
            address
          }
        }
        timesCollection {
          items {
            time
          }
        }
        phoneNumbersCollection {
          items {
            number
          }
        }
        emailsCollection {
          items {
            email
          }
        }
        instagramLink
        whatsAppNumber
      }
    }
  }
`;

export const floatingWhatsAppQuery = gql`
  query {
    floatingWhatsAppCollection(limit: 1) {
      items {
        phoneNumber
        photo {
          url
        }
        defaultMsg
      }
    }
  }
`;