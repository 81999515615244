import React, {useEffect, useState} from "react";
import "./Exams.css";
import Banner from "../../components/banner/Banner";
import {faHeartbeat} from "@fortawesome/free-solid-svg-icons";
import {ExamsPageFields} from "../../cms/content-types";
import ImageCard from "../../components/cards/image-card/ImageCard";
import {client} from "../../cms/GraphSQClient";
import {examsQuery} from "../../cms/Queries";
import Loading from "../../components/loading/Loading";

const Exams = () =>
{
    const [data, setData] = useState<ExamsPageFields | null>(null);

    const fetchData = async () =>
    {
        const response = await client.query({query: examsQuery});
        setData(response.data.examsPageCollection.items[0] as ExamsPageFields);
    }

    useEffect(() =>
    {
        fetchData();
    }, []);

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <Banner title={data.mainTitle} subtitle={data.subtitle} icon={faHeartbeat}/>

            <div className="exams-body-text-center container-fluid">
                <h2>{data.firstSectionTitle}</h2>
                <p>{data.firstSectionContent}</p>
            </div>

            <h2 className="main-exams">Principais Exames</h2>
            <div className="row container-fluid mx-w">
                {data.imgCardSetCollection.items.map((card, idx) => (
                    <div className="col-lg-4 col-sm-12" key={idx}>
                        <ImageCard key={idx} img={card.img} title={card.title} content={card.content}/>
                    </div>
                ))}
            </div>

            <div className="other-exams-bx">
                <h2>Outros Exames</h2>
                <div className="row container-fluid other-exams">
                    {data.examsCollection.items.map((exam, idx) => (
                        <p key={idx} className="col-lg-4 col-sm-12">{exam.name}</p>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Exams;
