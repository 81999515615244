import React from "react";
import "./Banner.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface BannerProps
{
    icon: IconProp;
    title: string;
    subtitle: string;
}

const Banner = ({icon, title, subtitle}: BannerProps) =>
{
    return (
        <div className="banner-body">
            <div className="solid-box">
                <div className="text-banner">
                    <h1 className="banner-title">{title}</h1>
                    <p className="banner-text">{subtitle}</p>
                </div>
                <div className="icon-wrapper">
                    <FontAwesomeIcon icon={icon} className="icon banner-icon"/>
                </div>
            </div>
        </div>
    );
}

export default Banner;
