import React from "react";
import "./Footer.css";
import {Link, useMatch} from "react-router-dom";
import {FooterFields} from "../../cms/content-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Footer = ({data}: { data: FooterFields }) =>
{
    const isBlue = !useMatch("/portal-medico");

    return (
        <div className="mx-w footer">
            <div className="footer-content">
                <div className="block-map">
                    <div className="map">
                        <iframe
                            title="Map"
                            src={`https://www.google.com/maps?q=${data.location.lat},${data.location.lon}&hl=es;z=15&output=embed`}
                            style={{border: 0}}
                            referrerPolicy="no-referrer-when-downgrade"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="map-text">
                        <div className="map-text-content">
                            <h3>Localização</h3>
                            {data.addressesCollection.items.map((address, idx) => (
                                <p key={idx}>{address.address}</p>
                            ))}
                            <h3>Horários</h3>
                            {data.timesCollection.items.map((time, idx) => (
                                <p key={idx}>{time.time}</p>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={isBlue ? "block-contact" : "block-contact-blue"}>
                    <div className="contact-text">
                        <div className="contact-text-content">
                            <h3>Fale Conosco</h3>
                            <h5>Telefone:</h5>
                            {data.phoneNumbersCollection.items.map((phone, idx) => (
                                <p key={idx}>{phone.number}</p>
                            ))}
                            <h5>Email:</h5>
                            {data.emailsCollection?.items.map((email, idx) => (
                                <p key={idx}>{email.email}</p>
                            ))}
                            <h5>Endereços:</h5>
                            {data.addressesCollection.items.map((address, idx) => (
                                <p key={idx}>{address.address}</p>
                            ))}
                        </div>
                        <br/>
                        <div className="social">
                            <a href={data.instagramLink} target="_blank" rel="noreferrer"
                               className="icon"><FontAwesomeIcon
                                icon={faInstagram} size="2x"/></a>
                            <a href={`https://wa.me/${data.whatsAppNumber}`} target="_blank"
                               rel="noreferrer" className="icon"><FontAwesomeIcon
                                icon={faWhatsapp}
                                size="2x"/></a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-links">
                <Link to="/">Home</Link>
                <Link to="/trabalhe-conosco" onClick={() => window.scrollTo(0, 0)}>Trabalhe Conosco</Link>
            </div>
        </div>
    );
};

export default Footer;
