import React, {useEffect, useState} from "react";
import "./AboutUs.css";
import Banner from "../../components/banner/Banner";
import {faAngleRight, faFileCircleQuestion, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WhiteIconCardSet from "../../components/cards/white-icon-cardset/WhiteIconCardSet";
import TextCardSet from "../../components/cards/text-cardset/TextCardSet";
import {AboutUsPageFields} from "../../cms/content-types";
import Loading from "../../components/loading/Loading";
import {aboutUsQuery} from "../../cms/Queries";
import {client} from "../../cms/GraphSQClient";

const AboutUs = () =>
{
    const [data, setData] = useState<AboutUsPageFields | null>(null);

    const fetchData = async () =>
    {
        try
        {
            const response = await client.query({query: aboutUsQuery});
            setData(response.data.aboutUsPageCollection.items[0] as AboutUsPageFields);
        }
        catch (error)
        {
            console.error("Error fetching about us data:", error);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <Banner title={data.mainTitle} subtitle={data.subtitle} icon={faUsers}/>

            <TextCardSet cards={data.cardSetCollection.items}/>

            <div className="c-gray">
            <div className="about-body-text-center container-fluid mx-w">
                <h2>Sobre Nós</h2>
                <p>{data.aboutUsText}</p>
            </div>
            </div>
            <div className="c-wine">
            <div className="cards-about container-flui mx-w">
                <WhiteIconCardSet data={{ iconCardSetCollection: { items: data.iconCardSetCollection.items } }} />
            </div>
            </div>


            <div className="about-body-l mx-w">
                <div className="about-body-content-l">
                    <h4 className="content-l-title">{data.section.title}</h4>
                    <p className="content-l-text">{data.section.content}</p>
                </div>
                <div className="about-body-content-l-img-wrapper">
                    <img className="about-body-content-l-img" src={data.section.img.url} alt="img"/>
                </div>
            </div>

            <div className="about-body-l mx-w">
                <div className="about-body-content-l-img-wrapper">
                    <img className="about-body-content-l-img" src={data.workWithUsSection.img.url} alt="img"/>
                </div>
                <div className="about-body-content-l">
                    <h4 className="content-l-title">{data.workWithUsSection.title}</h4>
                    <p className="content-l-text">{data.workWithUsSection.content}</p>
                    <a href="/trabalhe-conosco" className="about-link">
                    <button className="btn about-btn">Enviar Currículo
                        <span style={{marginLeft: "5px"}}>
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </span>
                    </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
