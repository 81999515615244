import React from "react";
import "./TextCard.css";
import TextCard, {TextCardProps} from "../text-card/TextCard";

interface TextCardSetProps
{
    cards: TextCardProps[];
}

const TextCardSet = ({cards}: TextCardSetProps) =>
{
    return (
        <div className="container-fluid">
            <div className="row card-set">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={`col-lg-4 col-sm-16 card-wrapper ${index % 2 === 0 ? "even-card" : "odd-card"}`}
                    >
                        <TextCard title={card.title} content={card.content}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TextCardSet;
