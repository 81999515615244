import React, {useEffect, useState} from "react";
import "./Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import TextCardSet from "../../components/cards/text-cardset/TextCardSet";
import ImageCard from "../../components/cards/image-card/ImageCard";
import SectionCarousel from "../../components/carousel/SectionCarousel";
import {HomePageFields} from "../../cms/content-types";
import {client} from "../../cms/GraphSQClient";
import {homeQuery} from "../../cms/Queries";
import Loading from "../../components/loading/Loading";
import {Link} from "react-router-dom";

const Home = () =>
{
    const [data, setData] = useState<HomePageFields | null>(null);
    const [scrolled, setScrolled] = useState(false);

    const fetchData = async () =>
    {
        try
        {
            const homeResponse = await client.query({query: homeQuery});
            setData(homeResponse.data.homeCollection.items[0] as HomePageFields);
        }
        catch (error)
        {
            console.error("Error fetching home data:", error);
        }
    }

    const handleScroll = () =>
    {
        if (window.scrollY > 50)
        {
            setScrolled(true);
        }
        else
        {
            setScrolled(false);
        }
    };

    useEffect(() =>
    {
        fetchData();

        window.addEventListener("scroll", handleScroll);

        return () =>
        {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div className="body">
            <div className="home-banner">
                <img src={data.mainImg.url} alt="banner" className="home-banner-img"/>
                <div className="d-flex justify-content-center align-items-center vh-100 solid-box">
                    <div className="container text-center text-banner">
                        <h1 className="banner-title">{data.headerTitle}</h1>
                        <p className="banner-text">{data.subtitle}</p>
                    </div>
                </div>
                <div>
                    {/* Dynamically apply the "scrolled" class to the button */}
                    <button className={`btn home-banner-btn ${scrolled ? "scrolled" : ""}`}>Agendar Consulta</button>
                </div>
            </div>

            <TextCardSet cards={data.firstCardSetCollection.items}/>
            <SectionCarousel items={data.sectionCarouselCollection.items}/>
            <div className="container-fluid mx-w">
                <div className="home-row-2">
                    <div className="home-coll-big">
                        <div className="video-container">
                            <iframe width="560" height="315"
                                    src="https://www.youtube.com/embed/0_9I6Rn6028?si=7tKJM94Y4qJTA94k&amp;controls=0"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                    {data.staffSectionCollection.items.map(imgCard => (
                        <div className="home-coll" key={imgCard.title}>
                            <ImageCard img={{url: imgCard.img.url}} title={imgCard.title}
                                       content={imgCard.content}/>
                            <Link to="/sobre#">
                                <button className="btn about-btn home-btn">Conheça a Clínica</button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <TextCardSet cards={data.secondCardSetCollection.items}/>

        </div>
    );
}

export default Home;
