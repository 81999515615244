import React, {useEffect, useState} from "react";
import "./ContactUs.css";
import {ContactUsPageFields} from "../../cms/content-types";
import Banner from "../../components/banner/Banner";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/loading/Loading";
import {contactUsQuery} from "../../cms/Queries";
import {client} from "../../cms/GraphSQClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ContactUs = () =>
{
    const [data, setData] = useState<ContactUsPageFields | null>(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const fetchData = async () =>
    {
        try
        {
            const response = await client.query({query: contactUsQuery});
            setData(response.data.contactUsPageCollection.items[0] as ContactUsPageFields);
        }
        catch (error)
        {
            console.error("Error fetching contact us data:", error);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    const handleSubmit = () =>
    {

    };

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <div className="contact-banner">
                <div className="contact-banner-content">
                    <h1 className="contact-banner-title">{data.mainTitle}</h1>
                    <p className="contact-banner-text">{data.subtitle}</p>
                </div>
                <div className="contact-banner-ico-container">
                    <FontAwesomeIcon icon={faPhone} className="icon banner-icon"/>
                </div>
            </div>
            <div className="container-fluid ff transparse">
                <form onSubmit={handleSubmit} className="f2">
                    <div className="form-cont">
                        <div className="fbr mm">
                            <div className="form-group mm">
                                <select className="form-select" aria-label="Assunto">
                                    {data.subjectsCollection.items.map((subject, idx) => (
                                        <option key={idx} value={subject.name}>{subject.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group mm">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Nome"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mm">
                                <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    placeholder="Telefone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className="form-group mm">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mm">
                            <textarea
                                className="form-control"
                                id="message"
                                placeholder="Mensagem"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            </div>
                        </div>
                        <div className="fbr mm">
                            <div className="row">
                                <h3>Entre em Contato</h3>
                                <div style={{wordWrap: 'break-word'}} className="col-lg-8">
                                    <h4>Email</h4>
                                    {data.emailsCollection.items.map((email, idx) => (
                                        <p key={idx}>{email.email}</p>
                                    ))}
                                </div>
                                <div className="col-lg-4">
                                    <h4>WhatsApp</h4>
                                    {data.whatsAppNumbersCollection.items.map((phone, idx) => (
                                        <p key={idx}>{phone.number}</p>
                                    ))}
                                </div>
                                <div>
                                    <h3>Perguntas Frequentes</h3>
                                    {data.frequentQuestionsCollection.items.map((frequentQuestion, idx) => (
                                        <div key={idx} className="dropdown">
                                            <button className="form-select" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                {frequentQuestion.question}
                                            </button>
                                            <ul className="dp-m dropdown-menu">
                                                <li>{frequentQuestion.answer}</li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn dpz-btn mm">
                        Enviar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;