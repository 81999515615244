import React, {useEffect, useState} from "react";
import "./Units.css";
import Banner from "../../components/banner/Banner";
import {faHospital} from "@fortawesome/free-solid-svg-icons";
import {UnitsPageFields} from "../../cms/content-types";
import Loading from "../../components/loading/Loading";
import {unitsQuery} from "../../cms/Queries";
import {client} from "../../cms/GraphSQClient";

const Units = () =>
{
    const [data, setData] = useState<UnitsPageFields | null>(null);

    const fetchData = async () =>
    {
        try
        {
            const response = await client.query({query: unitsQuery});
            setData(response.data.unitsPageCollection.items[0] as UnitsPageFields);
        }
        catch (error)
        {
            console.error("Error fetching units data:", error);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <Banner title={data.mainTitle} subtitle={data.subtitle} icon={faHospital}/>

            <div className="c-wine">
                <div className="insurance-body-l mx-w">
                    <div className="insurance-body-content-l">
                        <h5 className="content-l-title w-text">{data.firstSection.title}</h5>
                        <p className="content-l-text w-text">{data.firstSection.content}</p>
                    </div>
                    <div className="insurance-body-content-l-img-wrapper">
                        <img className="insurance-body-content-l-img"
                             src={data.firstSection.img.url} alt="img"/>
                    </div>
                </div>
            </div>

            <div className="insurance-body-l mx-w unit-bl">
                <div className="about-body-content-l">
                    <h5 className="content-l-title">{data.secondSection.title}</h5>
                    <p className="content-l-text">{data.secondSection.content}</p>
                </div>
                <div className="about-body-content-l-img-wrapper">
                    <img className="about-body-content-l-img"
                         src={data.secondSection.img.url} alt="img"/>
                </div>
            </div>
        </div>
    );
}

export default Units;
