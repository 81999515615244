import React from "react";
import "./IconCardSet.css";
import IconCard, {IconCardProps} from "../icon-card/IconCard";

interface IconCardSetProps
{
    cards: IconCardProps[];
}

const IconCardSet = ({cards}: IconCardSetProps) =>
{
    return (
        <div className="container-fluid">
            <div className="row i-card-set">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={`col-lg-4 col-sm-16 card-wrapper icon-card-set`}
                    >
                        <IconCard icon={card.icon} title={card.title} content={card.content}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default IconCardSet;