import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Layout from "./components/layout/Layout";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";

import {floatingWhatsAppQuery, footerQuery} from "./cms/Queries"; // Import queries for other components
import {client} from "./cms/GraphSQClient";
import {FloatingWhatsAppFields, FooterFields} from "./cms/content-types";
import ContactUs from "./pages/contact-us/ContactUs";
import AboutUs from "./pages/about-us/AboutUs";
import WorkWithUs from "./pages/work-with-us/WorkWithUs";
import DoctorPortal from "./pages/doctor-portal/DoctorPortal";
import Staff from "./pages/staff/Staff";
import Exams from "./pages/exams/Exams";
import Units from "./pages/units/Units";
import Loading from "./components/loading/Loading";

library.add(fas);
library.add(far);
library.add(fab);

function App()
{
    const [footerData, setFooterData] = useState<FooterFields | null>(null);
    const [floatingWhatsAppData, setFloatingWhatsAppData] = useState<FloatingWhatsAppFields | null>(null);

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            try
            {
                const footerResponse = await client.query({query: footerQuery});
                setFooterData(footerResponse.data.footerCollection.items[0] as FooterFields);

                const floatingWhatsAppResponse = await client.query({query: floatingWhatsAppQuery});
                setFloatingWhatsAppData(floatingWhatsAppResponse.data.floatingWhatsAppCollection.items[0] as FloatingWhatsAppFields);
            }
            catch (error)
            {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    if (!footerData || !floatingWhatsAppData)
    {
        return <Loading/>;
    }

    return (
        <Layout footerData={footerData} floatingWhatsAppData={floatingWhatsAppData}>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/exames" element={<Exams/>}/>
                <Route path="/fale-conosco" element={<ContactUs/>}/>
                <Route path="/sobre" element={<AboutUs/>}/>
                <Route path="/trabalhe-conosco" element={<WorkWithUs/>}/>
                <Route path="/portal-medico" element={<DoctorPortal/>}/>
                <Route path="/corpo-clinico" element={<Staff/>}/>
                <Route path="/unidades" element={<Units/>}/>
            </Routes>
        </Layout>
    );
}

export default App;
