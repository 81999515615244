import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faEye, faCrown } from "@fortawesome/free-solid-svg-icons";
import "./WhiteIconCardSet.css";
import WhiteIconCard, { WhiteIconCardProps } from "../white-icon-card/WhiteIconCard";

// Definindo os campos que vêm de IconCardFields
interface IconCardFields {
    title: string;
    content: string;
}

interface WhiteCardSetProps {
    cards: WhiteIconCardProps[]; // cards deve ser no formato correto
}

const WhiteIconCardSet = ({ cards }: WhiteCardSetProps) => {
    return (
        <div className="container-fluid">
            <div className="row i-card-set">
                {cards.map((card, index) => (
                    <div key={index} className={`col-lg-4 col-sm-16 card-wrapper icon-card-set`}>
                        <WhiteIconCard icon={card.icon} title={card.title} content={card.content} />
                    </div>
                ))}
            </div>
        </div>
    );
};

const AboutUs = ({ data }: { data: { iconCardSetCollection: { items: IconCardFields[] } } }) => {
    // Mapeando os dados de IconCardFields para o formato esperado por WhiteIconCardProps[]
    const cards: WhiteIconCardProps[] = data.iconCardSetCollection.items.map((item, index) => ({
        icon: index === 0 ? faBullseye : index === 1 ? faEye : faCrown, // Atribuindo ícones com base no índice
        title: item.title,
        content: item.content,
    }));

    return (
        <div className="c-wine">
            <div className="cards-about container-flui mx-w">
                {/* Passando os cards mapeados corretamente */}
                <WhiteIconCardSet cards={cards} />
            </div>
        </div>
    );
};

export default AboutUs;
