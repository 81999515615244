import React, {ReactNode, useEffect, useState} from "react";
import "./Layout.css";
import Footer from "../footer/Footer";
import NavBar from "../navbar/NavBar";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import {FloatingWhatsAppFields, FooterFields} from "../../cms/content-types";

interface LayoutProps
{
    floatingWhatsAppData: FloatingWhatsAppFields;
    footerData: FooterFields;
    children: ReactNode;
}

const Layout = ({floatingWhatsAppData, footerData, children}: LayoutProps) =>
{
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () =>
    {
        if (window.scrollY > 50)
        {
            setScrolled(true);
        }
        else
        {
            setScrolled(false);
        }
    };

    useEffect(() =>
    {
        window.addEventListener("scroll", handleScroll);
        return () =>
        {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div>
            <header className="fixed-top">
                <NavBar/>
            </header>

            <main>
                {children}
            </main>
            <FloatingWhatsApp
                accountName="MegaScan"
                avatar={floatingWhatsAppData.photo.url}
                phoneNumber={floatingWhatsAppData.phoneNumber.toString()}
                statusMessage="online"
                chatMessage={floatingWhatsAppData.defaultMsg}
                className={`whatsapp-element ${scrolled ? "scrolled" : ""}`}
            />

            <Footer data={footerData}/>
        </div>
    );
};

export default Layout;
