import React from "react";
import "./ImageCard.css";

export interface ImageCardProps
{
    img: {
        url: string;
    };
    title: string;
    content?: string;
}

const ImageCard = ({img, title, content}: ImageCardProps) =>
{
    return (
        <div className="img-card">
            <img src={img.url} className="card-img-top" alt="card-img"/>
            <div className="img-card-body">
                <h3 className="img-card-title">{title}</h3>
                {content && <p className="img-card-text">{content}</p>}
            </div>
        </div>
    );
}

export default ImageCard;
