import React from "react";
import "./IconCard.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faInfo} from "@fortawesome/free-solid-svg-icons";

export interface IconCardProps
{
    icon?: IconProp;
    title: string;
    content: string;
}

const IconCard = ({icon, title, content}: IconCardProps) =>
{
    return (
        <div className="ic-card">
            <div className="icon-circle">
                <FontAwesomeIcon icon={icon || faInfo} className="i-icon"/>
            </div>
            <div className="i-card-body">
                <p className="i-card-title">{title}</p>
                <p className="i-card-text">{content}</p>
            </div>
        </div>
    );
}

export default IconCard;
