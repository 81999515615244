import React from "react";
import "./TextCard.css";

export interface TextCardProps
{
    title: string;
    content: string;
}

const TextCard = ({title, content}: TextCardProps) =>
{
    return (
        <div className="card">
            <div className="card-body">
                <p className="card-title">{title}</p>
                <p className="card-text">{content}</p>
            </div>
        </div>
    );
}

export default TextCard;
