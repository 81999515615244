import React, {useEffect, useState} from "react";
import "./Staff.css";
import Banner from "../../components/banner/Banner";
import {faStethoscope} from "@fortawesome/free-solid-svg-icons";
import {StaffPageFields} from "../../cms/content-types";
import {client} from "../../cms/GraphSQClient";
import {staffQuery} from "../../cms/Queries";
import Loading from "../../components/loading/Loading";

const Staff = () =>
{
    const [data, setData] = useState<StaffPageFields | null>(null);

    const fetchData = async () =>
    {
        try
        {
            const response = await client.query({query: staffQuery});
            setData(response.data.staffPageCollection.items[0] as StaffPageFields);
        }
        catch (error)
        {
            console.error("Error fetching staff data:", error);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <Banner title={data.mainTitle} subtitle={data.subtitle} icon={faStethoscope}/>

            <div className="c-wine">
                <div className="insurance-body-l mg-15">
                    <div className="insurance-body-content-l">
                        <h5 className="content-l-title w-text">{data.firstSection.title}</h5>
                        <p className="content-l-text w-text">{data.firstSection.content}</p>
                    </div>
                    <div className="insurance-body-content-l-img-wrapper">
                        <img className="insurance-body-content-l-img"
                             src={data.firstSection.img.url} alt="img"/>
                    </div>
                </div>
            </div>

            <div className="insurance-body-l mx-w unit-bl">
                <div className="about-body-content-l">
                    <h5 className="content-l-title">{data.secondSection.title}</h5>
                    <p>{data.secondSection.content}</p>
                </div>
                <div className="about-body-content-l-img-wrapper">
                    <img className="about-body-content-l-img"
                         src={data.secondSection.img.url} alt="img"/>
                </div>

            </div>
        </div>
    );
}

export default Staff;
