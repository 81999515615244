import React from "react";
import {FileWithPath, useDropzone} from "react-dropzone";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Dropzone.css";

interface DropzoneProps
{
    setFile: (file: FileWithPath) => void;
}


const Dropzone = ({setFile}: DropzoneProps) =>
{
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1
    });

    if (acceptedFiles.length > 0)
    {
        setFile(acceptedFiles[0]);
    }

    return (
        <div className="container">
            <div {...getRootProps({className: "dropzone"})} className="dpz">
                <i></i>
                <input {...getInputProps()} />
                <FontAwesomeIcon icon={faCloudUploadAlt} size="3x"/><br/>
                <button type="button" onClick={open} className="btn dpz-btn">
                    Escolha o arquivo para enviar
                </button>
                <p>Ou os arraste e solte aqui</p>
            </div>
        </div>
    );
}

export default Dropzone;