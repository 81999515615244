import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

const httpLink = createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE_ID}`,
});

const authLink = setContext((_, {headers}) =>
{
    // Get the authentication token from wherever you store it
    const token = process.env.REACT_APP_TOKEN;
    // Check if a token exists
    if (token)
    {
        // Return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            }
        };
    }
    else
    {
        // If no token exists, return the headers as they are
        return {
            headers,
        };
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});