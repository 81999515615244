import React from "react";
import "./TopHeader.css";
import {Link} from "react-router-dom";

const TopHeader = () =>
{
    return (
        <div className="head">
            <Link to="/portal-medico">Acesso Ao Portal Médico</Link>
        </div>
    );
}

export default TopHeader;
