import React, {useEffect, useState} from "react";
import "./WorkWithUs.css";
import {WorkWithUsPageFields} from "../../cms/content-types";
import Banner from "../../components/banner/Banner";
import { faHandshake} from "@fortawesome/free-solid-svg-icons";
import {FileWithPath} from "react-dropzone";
import Dropzone from "../../components/dropzone/Dropzone";
import {client} from "../../cms/GraphSQClient";
import {workWithUsQuery} from "../../cms/Queries";
import Loading from "../../components/loading/Loading";

const WorkWithUs = () =>
{
    const [data, setData] = useState<WorkWithUsPageFields | null>(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [resume, setResume] = useState<FileWithPath | null>(null);

    const fetchData = async () =>
    {
        try
        {
            const response = await client.query({query: workWithUsQuery});
            setData(response.data.workWithUsPageCollection.items[0] as WorkWithUsPageFields);
        }
        catch (error)
        {
            console.error("Error fetching work with us data:", error);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    const setFile = (file: FileWithPath) =>
    {
        setResume(file);
    }

    const handleSubmit = (event: React.FormEvent) =>
    {
        event.preventDefault();

        console.log(resume);
    };

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <Banner title={data.mainTitle} subtitle={data.subtitle} icon={faHandshake}/>

            <div className="container-fluid mt-5 ff">

                <form onSubmit={handleSubmit} className="form-work">
                    <h1 className="mm">Envie Seu Currículo</h1>
                    <div className="form-group mm">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mm">
                        <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            placeholder="Telefone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="form-group mm">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mm">
                            <textarea
                                className="form-control"
                                id="message"
                                placeholder="Mensagem"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                    </div>
                    <div className="form-group-drop mm">
                        <Dropzone setFile={setFile}/>
                    </div>
                    <button type="submit" className="btn dpz-btn mm">
                        Enviar Currículo
                    </button>
                </form>
            </div>
        </div>
    );
};

export default WorkWithUs;
