import React, {useEffect, useState} from "react";
import "./DoctorPortal.css";
import {DoctorPortalPageFields} from "../../cms/content-types";
import {client} from "../../cms/GraphSQClient";
import {doctorPortalQuery} from "../../cms/Queries";
import Loading from "../../components/loading/Loading";
import ClickableCardCarousel from "../../components/cards/clickable-card-carousel/ClickableCardCarousel";
import IconCardSet from "../../components/cards/icon-cardset/IconCardSet";

const DoctorPortal = () =>
{
    const [data, setData] = useState<DoctorPortalPageFields | null>(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const fetchData = async () =>
    {
        try
        {
            const response = await client.query({query: doctorPortalQuery});
            setData(response.data.doctorPortalPageCollection.items[0] as DoctorPortalPageFields);
        }
        catch (error)
        {
            console.error("Error fetching doctor portal data:", error);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    const handleSubmit = () =>
    {

    };

    if (!data)
    {
        return <Loading/>;
    }

    return (
        <div>
            <div className="doctor-banner">
                <div className="doctor-banner-content">
                    <h1 className="doctor-banner-title">{data.mainTitle}</h1>
                    <p className="doctor-banner-text">{data.subtitle}</p>
                    <a type="button" className="btn doctor-banner-btn"
                       href="https://exames.megascanressonancia.com:8081/portal/WebLogin.aspx?ReturnUrl=%2fportal%2f"
                       target="_blank">Acessar Portal</a>
                </div>
                <div className="doctor-banner-img-container">
                    <img src={data.mainImg.url} className="doctor-banner-img" alt="Doctor"/>
                </div>
            </div>
            <div className="doctor-body" id="cards">
                <div className="doctor-body-content">
                    <h3 className="doctor-body-title dtt1">{data.sectionFirstTitle} <span
                        className="dtt2">{data.sectionSecondTitle}</span></h3>
                </div>
                <p className="doctor-body-text">{data.sectionContent}</p>
            </div>

            <div className="mx-w pdd">
                <h2 className="header">Médicos</h2>
                <ClickableCardCarousel cards={data.doctorCardSetCollection.items}/>
            </div>
            <div className="mx-w pdd med-content">
                <h2 className="header">Diferenciais</h2>
                <IconCardSet cards={data.iconCardSetCollection.items}/>
            </div>
            <div className="mx-w med-content">
                <h2>Entre em contato</h2>
            </div>
            <div className="width-50 med-content bg-l-blue ">
                <div className="form-group">
                    <select className="form-select" aria-label="Assunto">
                        {data.subjectsCollection.items.map((subject, idx) => (
                            <option key={idx} value={subject.name}>{subject.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <textarea
                        className="form-control"
                        id="message"
                        placeholder="Mensagem"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                </div>
                <button type="submit" className="btn dpz-btn" onClick={handleSubmit}>
                    Enviar
                </button>
            </div>
        </div>
    );
};

export default DoctorPortal;